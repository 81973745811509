.summary {
  background-color: var(--secondary);
}

.summary-text {
  color: var(--secondary-dark);
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
  line-height: 2;
  font-weight: 300;
  font-size: 21px;
  font-weight: 350;
  white-space: pre-wrap;
}
