.hero {
  background-color: var(--secondary);
  flex-wrap: wrap;
}

.hero-square {
  width: 30%;
  aspect-ratio: 1;
  min-width: 200px;
  border: 6px solid var(--secondary-dark);
  border-radius: 20px;
  bottom: 10vh;
  left: 5vw;
  position: relative;
}

.hero-image {
  width: 100%;
  border-radius: 20px;
  position: relative;
  top: 10vh;
  right: 10vw;
}
