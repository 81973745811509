.work-experience {
  background-color: var(--secondary-dark);
  flex-direction: column;
  justify-content: flex-start;

}

.work-experience-item {
  display: flex;
  flex-direction: column;
  align-self: center;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 8%;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  gap: 50px;
}

.work-experience h1 {
  color: var(--primary-dark);
}

.work-experience-text {
    color: var(--primary-dark);
    justify-content: flex-start;
    margin-left: 5%;
    margin-right: 5%;
  }

.work-experience-logo {
  width: 25%;
  max-width: 300px;
  min-width: 250px;
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
}

@media (max-width: 1120px) {
  .work-experience-item {
    flex-direction: column;
    align-items: center;
  }

  .work-experience-text {
    margin-left: 0;
    align-items: center;
    margin-right: 0;
    margin-left: 10%;
    margin-right: 10%;
  }

  .work-experience h1,h2 {
    color: var(--primary-dark);
    text-align: center;
  }
}
