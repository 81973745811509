.skills {
  background-color: var(--secondary-dark);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(250px, 1fr)
  ); /* Adjust the minmax for your needs */
  grid-gap: 20px; /* This adds space between grid items */
  justify-items: center; /* This centers items horizontally */
  align-items: center; /* This centers items vertically */
  width: 90%;
  margin-top: 20px;
}

.skill-technical-item {
  width: 100px;
  height: 120px; /* Keep or adjust height */
  fill: var(--primary-dark); /* SVG color adjustment */
}

.skill-people-item {
  padding: 10px;
  color: var(--primary-dark);
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
